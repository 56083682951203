@switch (component()) {
@case ("services-detail") {
<section class="inner-banner white__bg pb-80">
  <div class="inner-banner__container">
    <div class="service__content mini-container">
      <div class="main-banner__content-text">
        <h1 class="large main-banner__dark-title">
          {{ bannerSection()?.title }}
        </h1>
        <h5 class="service_description">
          {{ bannerSection()?.description }}
        </h5>
        @if (bannerSection()?.title) {
        <div class="button-group">
          <a [routerLink]="['/contact-us']" [queryParams]="{ section: 'getInTouch' }"
            class="btn btn-bordered active">Get Started</a>
        </div>
        }
      </div>
      @if (bannerSection()?.image) {
      <div class="service_illustration">
        <img [src]="bannerSection()?.image.url" class="vert-move" [alt]="bannerSection()?.image.alt" />
      </div>
      }
    </div>
  </div>
</section>
}
@case ("portfolio-detail") {
<section class="inner-banner project-bg portfolio_detail_banner">
  <div class="inner-banner__container">
    <div class="inner-banner__background-img project_bg2">
      <div class="inner-banner__content mini-container animatable fadeinup text-center">
        <h1 class="large">{{ bannerSection()?.title }}</h1>
        <h5>
          {{ bannerSection()?.description }}
        </h5>
      </div>
      @if (bannerSection()?.image) {
      <div class="portfolio_detail_banner_img">
        <img [src]="bannerSection()?.image.url" [alt]="bannerSection()?.image.alt" />
      </div>
      }
    </div>
  </div>
</section>
}
@default {
<section class="main-banner">
  <div class="main-banner__container">
    <div class="main-banner__background-img">
      <div class="main-banner__content">
        <div class="main-banner__content-text">
          <h1 class="large main-banner__content-title">
            {{ bannerSection()?.title }}
          </h1>
          <h5 class="main-banner__content-description">
            {{ bannerSection()?.description }}
          </h5>
          @if (bannerSection()?.title) {
          @if (component() === "career") {
          <div class="button-group banner-btn">
            <a routerLink="." appScrollToElement [elementRef]="scrollToExplore()" class="btn btn-bordered">Explore
              Opportunities</a>
            <a routerLink="." appScrollToElement [elementRef]="scrollToButton()" class="btn btn-bordered">Contact Us</a>
          </div>
          } @else {
          <div class="button-group">
            <a routerLink="." appScrollToElement [elementRef]="scrollToButton()" class="btn btn-bordered">{{
              component() === "contact-us"
              ? "Get In Touch"
              : "Contact Us"
              }}</a>
          </div>
          }
          }
        </div>
        @if (bannerSection()?.image) {
        <div class="main-banner__content-illustration">
          <img [src]="bannerSection()?.image.url" [alt]="bannerSection()?.image.alt" />
        </div>
        }
      </div>
    </div>

    <!-- <div class="app__wave-container">
      <img src="assets/images/wave.svg" alt="wave" />
    </div> -->
  </div>
</section>
}
}